<template>
    <div>
        <treatment-history-detail
            backRoute="ClinicNurseDetails"
            patientDetailRoute="ClinicPatientDetail"
        />
    </div>
</template>
<script>
import PatientTreatmentHistoryDetails from '../../general-component/treatment-history/TreatmentDetails'
export default {
    name:"ClinicPatientDetails",
    components:{
    'treatment-history-detail': PatientTreatmentHistoryDetails,
    },
    data(){
        return{

    }}
}
</script>
